.root {
  padding: 0 24px 5px 24px;

  .label {
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 8px;
    padding-top: 18px;
  }

  .validationErrorText {
    font-size: 12px;
    color: #ff5a60;
  }

  .input {
    display: flex;
    flex-direction: column;

    div {
      color: var(--color-text-primary);

      &:hover,
      :focus {
        border-radius: 8px;
        border-color: rgba(47, 128, 237, 0.5) !important;
      }
      &:focus-within {
        height: 44px !important;
        border-radius: 8px;
        border-color: rgba(47, 128, 237, 0.5) !important;
      }

      &:first-child {
        height: 44px;
        border-radius: 8px;
      }
    }
    .field {
      height: 44px !important;
      box-sizing: border-box;
      border-radius: 8px;

      &:first-child {
        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 100%;
          border-right: 1.3px solid rgba(106, 122, 152, 0.15);
          right: 43px;
          top: 0;
        }
      }

      &:focus-within,
      &:hover {
        border-radius: 8px;
        border-color: rgba(47, 128, 237, 0.5) !important;
      }
    }
  }

  .multiSelect {
    display: flex;
    flex-direction: column;
    .field {
      div {
        min-height: 38px !important;
        border-radius: 8px;
      }

      &:first-child {
        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 100%;
          border-right: 1.3px solid rgba(106, 122, 152, 0.15);
          right: 43px;
          top: 0;
        }
      }
    }
  }

  .dateField {
    display: flex;
    flex-direction: column;

    div {
      width: 100%;
      height: 44px;
    }
  }

  .switch {
    display: flex;
    flex-direction: column;

    .switchLabel {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.customizeMenuFooter {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  padding: 0 24px 24px 24px;

  .resetButton {
    width: 137px;
    margin-right: 8px;
    border: 1px solid #e2edff;
    border-radius: 12px;
    color: var(--color-tag-text);

    &:hover {
      color: var(--color-text-primary);
      opacity: 0.75;
    }
  }

  .submitButton {
    width: 201px;
    margin-left: 8px;
  }
}
